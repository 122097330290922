import React, { useEffect } from 'react';
import { IStore } from "../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { LOGGED_OUT_MESSAGE, SUBSCRIPTION_EXPIRED_MESSAGE } from '../../strings';
import { Auth as Authorization } from 'aws-amplify';
import * as AppActions from '../../redux/actions/appAction';
import * as UserActions from '../../redux/actions/userAction';
import { getDeviceId } from 'src/utils/miscUtils';
import * as NotificationAction from '../../redux/actions/notificationAction';
import CustomAlertDialog from '../CustomAlertDialog';

export default function HomeStatusBar() {

    const deviceId = useSelector((store: IStore) => store.notification.deviceId);

    const MINUTE_MS = 5 * 60 * 1000;

    const [dialogTitle, setDialogTitle] = React.useState("");
    const [dialogMessage, setDialogMessage] = React.useState("");

    const [visible, setVisible] = React.useState(false);
    const showDialog = () => setVisible(true);
    const hideDialog = () => setVisible(false);

    const dispatch = useDispatch();

    const showForceLogoutPopup = useSelector((store: IStore) => store.app.showForceLogoutPopup);
    const subscriptionStatus = useSelector((store: IStore) => store.user.subscriptionStatus);

    useEffect(() => {
        setDeviceId();
        fetchUserDetails();
    }, []);

    useEffect(() => {
        if (showForceLogoutPopup) {
            setDialogTitle("Logged out");
            setDialogMessage(LOGGED_OUT_MESSAGE)
            showDialog();
        }
    }, [showForceLogoutPopup]);

    useEffect(() => {
        if (subscriptionStatus !== "ACTIVE") {
            setDialogTitle("Subscription Expired");
            setDialogMessage(SUBSCRIPTION_EXPIRED_MESSAGE)
            showDialog();
        }
    }, [subscriptionStatus]);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchUserDetails()
        }, MINUTE_MS);

        return () => clearInterval(interval);
    }, [])

    async function setDeviceId() {
        var currentDeviceId = deviceId;
        if (currentDeviceId === null || currentDeviceId === undefined) {
            currentDeviceId = await getDeviceId();
            dispatch(NotificationAction.setDeviceId(currentDeviceId));
        }
    }

    async function fetchUserDetails() {
        Authorization.currentUserInfo().then(async (user) => {
            await fetchUserProfile();
        }).catch((e) => {
            console.log('fetch user profile error occurred', e)
            dispatch(AppActions.forceLogout());
        });
    }

    async function fetchUserProfile() {
        dispatch(UserActions.getUserProfile.request());
    }

    return (
        <CustomAlertDialog
            title={dialogTitle}
            description={dialogMessage}
            cancelText='Cancel'
            confirmText='Continue'
            cancelDisabled={true}
            isOpen={visible}
            onCancelClicked={hideDialog}
            onConfirmClicked={async () => {
                hideDialog()
                dispatch(AppActions.forceLogoutHandled());
                await Authorization.signOut()
                dispatch(AppActions.logout());
            }}
        />
    );
};