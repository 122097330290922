import React, { useEffect } from 'react';
import { IStore } from "../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { View, StyleSheet } from 'react-native';
import { Box, HStack, IconButton, Pressable, Text } from 'native-base';
import * as OrgActions from '../../redux/actions/orgAction';
import { IOrgStatistics, IOrgStatisticsItem } from 'src/models/UserStatistics';
import OrgStatisticsItemWithIcon from '../OrgStatisticsItemWithIcon';
import Collapsible from 'react-native-collapsible';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { textStyle } from 'src/styles/textStyle';

interface IOrgSuperAdminStatusBarProps {
    showCollapsibleLayout?: boolean;
}

export default function OrgSuperAdminStatusBar(props: IOrgSuperAdminStatusBarProps) {
    const dispatch = useDispatch();

    const [expanded, setExpanded] = React.useState(true);
    const handlePress = () => setExpanded(!expanded);

    const organizationId = useSelector((store: IStore) => store.user.userProfile.response?.org.orgId);
    const grainIdFilter = useSelector((store: IStore) => store.app.grainIdFilter);
    const centerIdFilter = useSelector((store: IStore) => store.app.centerIdFilter);
    const stateFilter = useSelector((store: IStore) => store.app.stateFilter);
    const districtFilter = useSelector((store: IStore) => store.app.districtFilter);
    const searchBy = useSelector((store: IStore) => store.app.searchQuery?.type);
    const searchQuery = useSelector((store: IStore) => store.app.searchQuery?.query);
    const dateRange = useSelector((store: IStore) => store.app.dateRange);

    const userStatistics: IOrgStatistics | undefined = useSelector((store: IStore) => store.org.orgStatistics)

    useEffect(() => {
        if (organizationId) {
            dispatch(OrgActions.fetchOrgStatistics.request({
                orgId: organizationId,
                centerId: centerIdFilter || undefined,
                grainId: grainIdFilter || undefined,
                farmerCode: searchBy === 'farmerCode' && searchQuery
                    ? searchQuery : undefined,
                state: stateFilter || undefined,
                district: districtFilter || undefined,
                startDate: dateRange?.from || undefined,
                endDate: dateRange?.to || undefined,
            }));
        }
    }, [organizationId,
        grainIdFilter,
        centerIdFilter,
        stateFilter,
        districtFilter,
        searchBy,
        searchQuery,
        dateRange,
    ]);

    const statisticsContainer = () => (
        <View style={styles.statisticsContainer}>
            {
                userStatistics?.statistics.map((item: IOrgStatisticsItem, index) => {
                    return <OrgStatisticsItemWithIcon
                        title={item.display_name}
                        key={index}
                        color={item.color}
                        icon={item.icon}
                        value={item.value} />
                })
            }
        </View>
    );

    return (
        <Box>
            {
                props.showCollapsibleLayout &&
                <Pressable
                    onPress={() => {
                        handlePress();
                    }}
                    rounded="8"
                    overflow="hidden"
                    m="1" p="1">
                    <HStack style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Text style={textStyle.statisticsTitle}>Statistics</Text>
                        <IconButton
                            variant="unstyled"
                            onPress={handlePress}
                            _focus={{
                                borderWidth: 0
                            }}
                            icon={<MaterialCommunityIcons name={expanded ? "chevron-up" : "chevron-down"} size={24} color="black" />}
                            _icon={{
                                color: "black"
                            }} />
                    </HStack>
                </Pressable>
            }

            {
                props.showCollapsibleLayout ?
                    <Collapsible collapsed={!expanded}>
                        {statisticsContainer()}
                    </Collapsible> :
                    statisticsContainer()
            }
        </Box>
    );
};
const styles = StyleSheet.create({
    statisticsContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        padding: 4,
    },
});