import React, { useEffect } from 'react';
import * as GrainProfileActions from '../../../redux/actions/grainProfileAction';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../redux/reducers';
import { View } from 'react-native';
import { Select, Text } from "native-base";
import { getUniqueItems, toTitleCase } from 'src/utils/utils';
import { ARYA_ORG_ID } from 'src/constants';
import { textStyle } from 'src/styles/textStyle';
import { containerStyle } from 'src/styles/containerStyle';
import { selectProps } from 'src/styles/selectProps';

export default function GrainProfileDropdown() {

    const RICE_GRAIN_ID = "18"

    const orgId = useSelector((store: IStore) => store.user.userProfile.response?.org.orgId);
    const grainId = useSelector((store: IStore) => store.grainType.grainId);
    const grainProfiles = useSelector((store: IStore) => store.grainProfile.grainProfiles);
    const grainProfileMappings = useSelector((store: IStore) => store.grainProfile.grainProfileMappings);
    const riceProfileSelectionEnabled = useSelector((store: IStore) => store.user.userProfile.response?.settings.rice_profile_selection_enabled) || false;

    const profileId = useSelector((store: IStore) => store.grainProfile.profileId);

    const dispatch = useDispatch();

    const [processes, setProcesses] = React.useState<any[]>([]);
    const [sizes, setSizes] = React.useState<any[]>([]);
    const [colors, setColors] = React.useState<any[]>([]);

    const [selectedProcess, setSelectedProcess] = React.useState<string | undefined>(undefined);
    const [selectedSize, setSelectedSize] = React.useState<string | undefined>(undefined);
    const [selectedColor, setSelectedColor] = React.useState<string | undefined>(undefined);

    useEffect(() => {
        var proc: any[] = getUniqueItems(grainProfileMappings, 'process')
        var si = getUniqueItems(grainProfileMappings, 'size')
        var col = getUniqueItems(grainProfileMappings, 'color')

        setProcesses(proc)
        setSizes(si)
        setColors(col)
        setSelectedProcess(proc[0])
        setSelectedSize(si[0])
        setSelectedColor(col[0])
    }, [grainProfileMappings]);

    useEffect(() => {
        if (grainId !== RICE_GRAIN_ID) {
            return
        }
        var profile = grainProfileMappings?.find((item) => {
            return item.process === selectedProcess && item.size === selectedSize && selectedColor === item.color
        })

        if (profile) {
            dispatch(GrainProfileActions.setSelectedGrainProfileMapping(profile));
            dispatch(GrainProfileActions.setProfileId(profile?.profileId));
        }
    }, [selectedProcess, selectedSize, selectedColor]);

    const onSelectedValueChanged = (value: any) => {
        dispatch(GrainProfileActions.setProfileId(value));
    };

    const beautifyLabel = (val: string) => {
        val = val.replace('_', " ")
        return toTitleCase(val)
    }

    return (
        <View style={containerStyle.componentContainer}>
            {
                (riceProfileSelectionEnabled ? (grainId !== RICE_GRAIN_ID) : true)
                &&
                <>
                    <Text style={textStyle.subSectionText}>Select Grain Profile</Text>
                    <Select selectedValue={profileId}
                        minWidth="200"
                        accessibilityLabel="Choose grain profile"
                        placeholder="Choose grain profile"
                        _selectedItem={selectProps.selectedItemProps}
                        mt={1}
                        onValueChange={itemValue => onSelectedValueChanged(itemValue)}>
                        {
                            grainProfiles !== undefined && grainProfiles.length > 0 && grainProfiles.map((profile) => {
                                return <Select.Item key={profile['profileId']} label={profile['profileName']} value={profile['profileId']} />
                            })
                        }
                    </Select>
                </>}
            {grainId === RICE_GRAIN_ID && <>
                <Text style={textStyle.subSectionText}>Select Rice Process</Text>
                <Select
                    selectedValue={selectedProcess}
                    minWidth="200"
                    accessibilityLabel="Choose Rice Process"
                    placeholder="Choose Rice Process" _selectedItem={selectProps.selectedItemProps} mt={1} onValueChange={itemValue => setSelectedProcess(itemValue)}>
                    {
                        processes.map((process) => {
                            return <Select.Item key={process} label={beautifyLabel(process)} value={process} />
                        })
                    }
                </Select>
                <Text style={textStyle.subSectionText}>Select Rice Size</Text>
                <Select
                    selectedValue={selectedSize}
                    minWidth="200"
                    accessibilityLabel="Choose Rice Size"
                    placeholder="Choose Rice Size" _selectedItem={selectProps.selectedItemProps} mt={1} onValueChange={itemValue => setSelectedSize(itemValue)}>
                    {
                        sizes.map((size) => {
                            return <Select.Item key={size} label={beautifyLabel(size)} value={size} />
                        })
                    }
                </Select>
                <Text style={textStyle.subSectionText}>Select Rice Color</Text>
                <Select
                    selectedValue={selectedColor}
                    minWidth="200"
                    accessibilityLabel="Choose Rice Color"
                    placeholder="Choose Rice Color" _selectedItem={selectProps.selectedItemProps} mt={1} onValueChange={itemValue => setSelectedColor(itemValue)}>
                    {
                        colors.map((color) => {
                            return <Select.Item key={color} label={beautifyLabel(color)} value={color} />
                        })
                    }
                </Select>
            </>}
        </View>
    );
}