import React, { useEffect } from 'react';
import { IStore } from "../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { View } from 'react-native';
import { IJob, IJobs, JobStatus } from 'src/models';
import OrgStatisticsItem from '../OrgStatisticsItem';
import Collapsible from 'react-native-collapsible';
import { Box, HStack, IconButton, Text, Pressable } from 'native-base';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { textStyle } from 'src/styles/textStyle';
import { fetchOrgStatistics } from '../../redux/actions/orgAction';

export default function OrgStatusBar({
    organizationId,
    showCollapsibleLayout = true }) {
    const dispatch = useDispatch();

    const [expanded, setExpanded] = React.useState(true);
    const handlePress = () => setExpanded(!expanded);

    const [last24hoursJobs, setLast24hoursJobs] = React.useState(0);
    const [last7daysJobs, setLast7daysJobs] = React.useState(0);

    const centers = useSelector((store: IStore) => store.org.centers.centers);
    const orgJobs: IJobs | null | undefined = useSelector((store: IStore) => store.job.orgJobs.jobs);

    useEffect(() => {
        if (organizationId) {
            dispatch(fetchOrgStatistics.request(organizationId));
        }
        if (orgJobs) {
            updateStatistics();
        }
    }, [orgJobs, organizationId]);

    const updateStatistics = async () => {
        let currentDate = new Date();
        let currentTimeInEpochs = currentDate.getTime() / 1000;

        var time24hoursAgoInEpochs = currentTimeInEpochs - (24 * 60 * 60);
        var time7daysAgoInEpochs = currentTimeInEpochs - (24 * 7 * 60 * 60);

        const jobs = orgJobs?.Items.filter((item) => isDisplayableJob(item.jobStatus))

        const jobsInLast24hours = jobs?.filter((item: IJob) => item.createdAt >= time24hoursAgoInEpochs).length || 0
        const jobsInLast7days = jobs?.filter((item: IJob) => item.createdAt >= time7daysAgoInEpochs).length || 0
        setLast24hoursJobs(jobsInLast24hours)
        setLast7daysJobs(jobsInLast7days)
    }

    const isDisplayableJob = (status: JobStatus) => {
        return [
            JobStatus.JOB_IMAGE_UPLOADED,
            JobStatus.JOB_IN_PROGRESS,
            JobStatus.JOB_COMPLETED,
            JobStatus.JOB_FAILED
        ].includes(status)
    }

    return (
        <Box>
            {
                showCollapsibleLayout &&
                <Pressable
                    onPress={() => {
                        handlePress();
                    }}
                    rounded="8"
                    overflow="hidden"
                    m="2" p="4">
                    <HStack style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Text style={textStyle.statisticsTitle}>Statistics</Text>
                        <IconButton
                            variant="unstyled"
                            onPress={handlePress}
                            _focus={{
                                borderWidth: 0
                            }}
                            icon={<MaterialCommunityIcons name={expanded ? "chevron-up" : "chevron-down"} size={24} color="black" />}
                            _icon={{
                                color: "black"
                            }} />
                    </HStack>
                </Pressable>
            }

            {
                showCollapsibleLayout ?
                    <Collapsible collapsed={!expanded}>
                        <View style={{
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'center'
                        }}>
                            <OrgStatisticsItem title="Active Centers" value={`${centers ? centers.length : 0}`} subTitle="" />
                            <OrgStatisticsItem title="Last day" value={`${last24hoursJobs}`} subTitle="samples analysed" />
                            <OrgStatisticsItem title="Last week" value={`${last7daysJobs}`} subTitle="samples analysed" />
                        </View>
                    </Collapsible> :
                    (
                        <View style={{
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'center'
                        }}>
                            <OrgStatisticsItem title="Active Centers" value={`${centers ? centers.length : 0}`} subTitle="" />
                            <OrgStatisticsItem title="Last day" value={`${last24hoursJobs}`} subTitle="samples analysed" />
                            <OrgStatisticsItem title="Last week" value={`${last7daysJobs}`} subTitle="samples analysed" />
                        </View>
                    )
            }
        </Box>
    );
};