import React, { useEffect } from 'react';
import { IStore } from "../../redux/reducers";
import { useSelector } from "react-redux";
import { Table, TableWrapper, Cell } from 'react-native-table-component';
import { View } from 'react-native';
import { Text } from 'native-base';
import { JobStatus } from '../../models';
import { textStyle } from 'src/styles/textStyle';
import { tableStyle } from 'src/styles/tableStyle';

export default function GrainCumulativeAnalysisTable() {

  const colorMode = useSelector((store: IStore) => store.app.colorMode);
  const job = useSelector((store: IStore) => store.job.jobStatus.job);
  const jobStatus = useSelector((store: IStore) => store.job.currentJobStatus);

  const [state, setState] = React.useState({
    kernelCount: 0,
    tableHead: ['Name', 'Average', 'S.D.'],
    tableData: [
      ['Length', '0', '0'],
      ['Width', '0', '0'],
      ['Aspect Ratio', '0', '0'],
      ['Area(mm2)', '0', '0'],
    ]
  });

  useEffect(() => {
    if (jobStatus == JobStatus.JOB_COMPLETED && job) {
      var result = [
        ["Length", `${beautify(job.results?.avg_length)}`, `${beautify(job.results?.std_length)}`],
        ["Width", `${beautify(job.results?.avg_width)}`, `${beautify(job.results?.std_width)}`],
        ["Aspect Ratio", `${beautify(job.results?.avg_aspect_ratio)}`, `${beautify(job.results?.std_aspect_ratio)}`],
        ["Area (mm2)", `${beautify(job.results?.avg_area_base)}`, `${beautify(job.results?.std_area_base)}`]
      ]

      if(job.grainId === '18') {
        result.push(["Head Rice Length", `${beautify(job.results?.avg_length_of_broken_grains)}`, `${beautify(job.results?.std_length)}`])
      }
      
      setState({
        ...state,
        tableData: result
      });
    }
  }, [job]);

  const beautify = (data: any) => {
    if (!isNaN(parseFloat(data)) && !isNaN(data - 0)) {
      let floatData = parseFloat(data);
      if (Math.ceil(floatData) == Math.floor(floatData)) {
        return data;
      } else {
        return floatData.toFixed(2);
      }
    } else {
      return data;
    }
  }

  const textElement = (data: any) => (
    <Text style={[tableStyle.cellText, {
      color: colorMode === 'dark' ? 'white' : 'black'
    }]}>{data}</Text>
  );


  return (
    <View>
      <Text style={textStyle.tableTitleText}>Cumulative Analysis</Text>
      <Table borderStyle={tableStyle.border}>
        <TableWrapper style={tableStyle.head}>
          {
            state.tableHead.map((cellData, index) => (
              <Cell key={index} data={cellData} textStyle={tableStyle.cellText} />
            ))
          }
        </TableWrapper>
        {
          state.tableData.map((rowData, index) => (
            <TableWrapper key={index} style={tableStyle.row}>
              {
                rowData.map((cellData, cellIndex) => (
                  <Cell key={cellIndex} data={textElement(beautify(cellData))} textStyle={tableStyle.cellText} />
                ))
              }
            </TableWrapper>
          ))
        }
      </Table>
    </View>
  );
};