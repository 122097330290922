import React, { useEffect } from 'react';
import { IStore } from "../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import * as JobActions from '../../../redux/actions/jobAction';
import { FlatList, RefreshControl } from 'react-native';
import { JobStatus, IUserJobListItem, ISearchQuery, IDateRange, IJob } from '../../../models';
import { Box, Button, View } from 'native-base';
import UserJobHistoryItem from '../../listItem/UserJobHistoryItem';
import { listStyle } from 'src/styles/listStyle';
import { containerStyle } from 'src/styles/containerStyle';
import { downloadScannedImageFromId } from 'src/services/download';

interface IUserJobHistoryListProps {
  navigation: any;
  pageType: string; // tabs or single
}

export default function UserJobHistoryList(props: IUserJobHistoryListProps) {

  const [listData, setListData] = React.useState<IUserJobListItem[]>([]);

  const [refreshing, setRefreshing] = React.useState(false);
  const [selectedJobId, setSelectedJobId] = React.useState("");

  const userJobs = useSelector((store: IStore) => store.job.userJobs.jobs);
  const username = useSelector((store: IStore) => store.user.userProfile.response?.username);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchUserJobs();
    pollUserJobs();
  }, [username]);

  useEffect(() => {
    let listItems: IUserJobListItem[] = []
    userJobs?.Items
      .filter((item) => isDisplayableJob(item.jobStatus))
      .forEach((item) => {
        listItems.push({
          "jobId": item.jobId,
          "jobStatus": item.jobStatus,
          "grainId": item.grainId,
          "createdAt": item.createdAt,
          "variety": item.info !== undefined
            && "variety" in item.info
            ? item.info["variety"] as string : "Default"
        })
      })
    setListData(listItems)
  }, [userJobs]);

  const pollUserJobs = async () => {
    if (username !== undefined) {
      dispatch(JobActions.pollUserJobs.request({
        'username': username
      }))
    }
  }

  const fetchUserJobs = async () => {
    if (username !== undefined) {
      dispatch(JobActions.userJobs.request({
        'username': username
      }))
    }
  }

  const isDisplayableJob = (status: JobStatus) => {
    return [
      JobStatus.JOB_IMAGE_UPLOADED,
      JobStatus.JOB_IN_PROGRESS,
      JobStatus.JOB_COMPLETED,
      JobStatus.JOB_FAILED
    ].includes(status)
  }

  const grainTypes = useSelector((store: IStore) => store.user.userProfile.response?.grains);
  const grainIdFilter = useSelector((store: IStore) => store.app.grainIdFilter) || "all";
  const searchQuery: ISearchQuery | undefined = useSelector((store: IStore) => store.app.searchQuery);
  const dateRange: IDateRange | undefined = useSelector((store: IStore) => store.app.dateRange);

  useEffect(() => {
    updateJobList();
  }, [grainIdFilter, searchQuery, dateRange, userJobs, grainTypes]);

  const updateJobList = () => {
    let listItems: IUserJobListItem[] = []
    userJobs?.Items
      .filter((item) => isDisplayableJob(item.jobStatus))
      .filter((item) => filterByGrainId(item.grainId))
      .filter((item: IJob) => filterBySearchQuery(item))
      .filter((item: IJob) => filterByDateRange(item))
      .forEach((item) => {
        listItems.push({
          "jobId": item.jobId,
          "jobStatus": item.jobStatus,
          "grainId": item.grainId,
          "createdAt": item.createdAt,
          "variety": item.info !== undefined
            && "variety" in item.info
            ? item.info["variety"] as string : "Default"
        })
      })
    setListData(listItems)
  }

  const filterByGrainId = (id: any) => {
    if (grainIdFilter === undefined
      || grainIdFilter === ""
      || grainIdFilter === "0"
      || grainIdFilter === "all") {
      return true
    }
    return id === grainIdFilter
  }

  const filterBySearchQuery = (item: IJob) => {
    if (searchQuery === undefined || searchQuery?.query === undefined || searchQuery?.query === "") {
      return true
    }

    if (searchQuery.type === 'jobId') {
      return item.jobId.includes(searchQuery.query)
    }
    return false
  }

  const filterByDateRange = (item: IJob) => {
    if (dateRange === undefined || dateRange?.from === undefined || dateRange?.to === undefined) {
      return true
    }

    return item.createdAt >= dateRange.from && item.createdAt <= dateRange.to;
  }


  const renderItem = ({ item }) => {
    return <UserJobHistoryItem
      item={item}
      retryJobCallback={() => {
        dispatch(JobActions.queueJob.request({
          'jobId': item.jobId
        }));
      }}
      itemCallback={() => {
        setSelectedJobId(item.jobId);
        const selectedJobStatus = userJobs?.Items.find((job) => job.jobId === item.jobId);
        if (selectedJobStatus !== undefined) {
          dispatch(JobActions.jobStatus.success(selectedJobStatus));
          dispatch(JobActions.setJobId(item.jobId));
          dispatch(JobActions.pollJobStatus.request(item.jobId));
        }

        if (props.pageType === 'tabs') {
          props.navigation.jumpTo('Analysis');
        } else {
          props.navigation.navigate('ResultsPage');
        }

      }} downloadImageCallback={async function (item: IUserJobListItem) {
        await downloadScannedImageFromId(item.jobId)
      }} />
  };

  const renderFooter = () => {
    return (
      <Box style={listStyle.footer}>
        <Button onPress={() => {
          fetchUserJobs()
        }} isLoading={refreshing} variant="subtle">Load more</Button>
      </Box>
    );
  };

  return (
    <View style={containerStyle.listContainer}>
      <FlatList
        data={listData.sort((a: any, b: any) => {
          return b.createdAt - a.createdAt
        })}
        renderItem={renderItem}
        keyExtractor={item => item.jobId}
        onEndReached={fetchUserJobs}
        ListFooterComponent={renderFooter}
        onEndReachedThreshold={0.3}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={() => {
            fetchUserJobs();
          }} />
        }
      />
    </View>
  );
};