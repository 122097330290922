import React from 'react';
import { View, Text } from 'react-native';
import { Pressable, Box, HStack } from 'native-base';
import { listStyle } from 'src/styles/listStyle';
import { cardStyle } from 'src/styles/cardStyle';
import TextWithIcon from '../../TextWithIcon';
import { ISurveyor } from '../../../models/Surveyor';

interface SurveyorListItemProps {
  item: ISurveyor;
}

export default function SurveyorListItem({ item }: SurveyorListItemProps) {
  return (
    <Pressable
      onPress={() => {
        // Add your item click logic here
      }}
      style={cardStyle.normalCard}
      rounded="8"
      overflow="hidden"
      borderWidth="1"
      borderColor="coolGray.300"
      shadow="3"
      bg="coolGray.100"
      p="4"
    >
      <Box>
        <View style={listStyle.cardSection}>
          <View style={{ width: "70%" }}>
            <Text style={{ ...listStyle.title, fontSize: 18, fontWeight: 'bold' }}>
              {item.name}
            </Text>
            <HStack space={2} mt={2} flexWrap="wrap">
              <TextWithIcon icon="account" text={item.surveyorId} />
              <TextWithIcon icon="phone" text={item.phoneNumber} />
              {
                item.aadharId &&
                <TextWithIcon icon="card-account-details" text={item.aadharId} />
              }
            </HStack>
          </View>
        </View>
      </Box>
    </Pressable>
  );
}
