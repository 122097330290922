import React from 'react';
import { IStore } from "../../../redux/reducers";
import { useSelector } from "react-redux";
import { View } from 'react-native';
import { IUserJobListItem, JobStatus } from '../../../models';
import { getDisplayableJobId, getBadgeColor, getStatusMessage, getFormattedDate } from '../../../utils/jobUtils'
import { Badge, Box, Pressable, HStack, Text, VStack, Menu } from 'native-base';
import { cardStyle } from 'src/styles/cardStyle';
import { listStyle } from 'src/styles/listStyle';
import TextWithIcon from '../../TextWithIcon';
import MaterialIconByName from '../../MaterialIconByName';
interface UserJobHistoryListProps {
    item: IUserJobListItem
    itemCallback: (item: IUserJobListItem) => void;
    retryJobCallback: (item: IUserJobListItem) => void;
    downloadImageCallback: (item: IUserJobListItem) => void;
}

export default function UserJobHistoryItem(props: UserJobHistoryListProps) {

    const grainTypes = useSelector((store: IStore) => store.user.userProfile.response?.grains);
    const currentStack = useSelector((store: IStore) => store.app.currentStack);

    const getGrainNameWithVariety = (grainId: string, varietyName: string | null | undefined): string => {
        const grainName = grainTypes?.filter(grain => grain.grainId === grainId)[0]?.name || "";

        if (currentStack === "appViewOnlyUser") {
            return varietyName
                && varietyName !== null
                && varietyName !== ""
                ? `${grainName}- ${varietyName}` : `${grainName}: Default`;
        }

        return grainName;
    }

    return (
        <Pressable
            onPress={() => {
                props.itemCallback(props.item)
            }}
            style={cardStyle.normalCard}
            rounded="8"
            overflow="hidden"
            borderWidth="1"
            shadow="3"
            p="4">
            <Box>
                <HStack style={{
                    justifyContent: "space-between",
                }}>
                    <VStack>
                        <Text style={listStyle.title}>Job ID: {getDisplayableJobId(props.item.jobId)}</Text>
                        <HStack space={2} alignItems="center">
                            <TextWithIcon icon="barley" text={getGrainNameWithVariety(props.item.grainId, props.item.variety)} />
                            <TextWithIcon icon="clock-time-nine" text={getFormattedDate(props.item.createdAt)} />
                        </HStack>
                    </VStack>
                    <View style={{ justifyContent: 'center' }}>
                        <View style={{ flexDirection: "row" }}>
                            <Badge variant='solid' colorScheme={getBadgeColor(props.item.jobStatus)}>{getStatusMessage(props.item.jobStatus)}</Badge>
                            {props.item.jobStatus !== JobStatus.JOB_COMPLETED &&
                                <View style={{ justifyContent: 'center', marginLeft: 8 }}>
                                    <Menu trigger={triggerProps => {
                                        return <Pressable accessibilityLabel="More options" {...triggerProps}>
                                            <MaterialIconByName name="dots-vertical" />
                                        </Pressable>;
                                    }}>
                                        <Menu.Item onPress={() => {
                                            props.downloadImageCallback(props.item)
                                        }}>Download image</Menu.Item>
                                        <Menu.Item onPress={() => {
                                            props.retryJobCallback(props.item)
                                        }}>Retry</Menu.Item>
                                    </Menu>
                                </View>
                            }
                        </View>
                    </View>
                </HStack>
            </Box>
        </Pressable>
    );
};