import React, { useEffect } from 'react';
import * as OrganizationActions from "../../../redux/actions/orgAction";
import * as AppActions from "../../../redux/actions/appAction";
import { View } from 'react-native';
import { IStore } from "../../../redux/reducers";
import { useSelector, useDispatch } from "react-redux";
import { Select, Text } from "native-base";
import { containerStyle } from 'src/styles/containerStyle';
import { textStyle } from 'src/styles/textStyle';
import { selectProps } from 'src/styles/selectProps';
import { isNullOrUndefinedOrEmpty } from 'src/utils/utils';

export interface IDistrictDropdownProps {
  all?: boolean; // if true, show "All districts" option and refers to Filter modal
}

export default function DistrictDropdown(props: IDistrictDropdownProps) {

  const centers = useSelector((store: IStore) => store.org.centers.centers);
  const orgId = useSelector((store: IStore) => store.user.userProfile.response?.org.orgId);
  const stateFilter = useSelector((store: IStore) => store.app.stateFilter) || "all";

  const districts = Array.from(new Set(centers
    ?.filter(center => stateFilter === "all" || center.state === stateFilter)
    .map(center => center.addressDistrict)))
    .filter(state => isNullOrUndefinedOrEmpty(state) === false).sort();

  const districtFilter = useSelector((store: IStore) => store.app.districtFilter) || "all";

  const [selectedDistrict, setSelectedDistrict] = React.useState<string | undefined>(props.all ? "all" : undefined);

  useEffect(() => {
    if (props.all) {
      setSelectedDistrict(districtFilter);
    } else {
      setSelectedDistrict(districtFilter);
    }
  }, [districtFilter, stateFilter]);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchOrgCenters();
  }, [orgId]);

  const fetchOrgCenters = async () => {
    if (orgId !== undefined) {
      dispatch(OrganizationActions.getCenters.request({
        orgId: orgId
      }));
    }
  }

  const onSelectedValueChanged = (val: any) => {
    if (!districts) {
      return;
    }

    if (props.all) {
      dispatch(AppActions.setDistrictFilter(val));
    }
  }

  return (
    <View style={containerStyle.componentContainer}>
      <Text style={textStyle.subSectionText}>Select District</Text>
      <Select style={{ width: "95%" }} selectedValue={selectedDistrict}
        accessibilityLabel="Choose district"
        placeholder="Choose district" _selectedItem={selectProps.selectedItemProps} mt={1} onValueChange={itemValue => onSelectedValueChanged(itemValue)}>
        {props.all && <Select.Item label={"All districts"} value="all" />}
        {
          districts.map((district, index) => {
            return <Select.Item key={index} label={district} value={district} />
          })
        }
      </Select>
    </View>
  );
};
