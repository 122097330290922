import React, { useEffect } from 'react';
import * as OrganizationActions from "../../../redux/actions/orgAction";
import * as AppActions from "../../../redux/actions/appAction";
import { View } from 'react-native';
import { IStore } from "../../../redux/reducers";
import { useSelector, useDispatch } from "react-redux";
import { Select, Text } from "native-base";
import { containerStyle } from 'src/styles/containerStyle';
import { textStyle } from 'src/styles/textStyle';
import { selectProps } from 'src/styles/selectProps';
import { isNullOrUndefinedOrEmpty } from 'src/utils/utils';

export interface IStateDropdownProps {
  all?: boolean; // if true, show "All states" option and refers to Filter modal
}

export default function StateDropdown(props: IStateDropdownProps) {

  const centers = useSelector((store: IStore) => store.org.centers.centers);
  const orgId = useSelector((store: IStore) => store.user.userProfile.response?.org.orgId);

  // Extract unique states from centers and convert the Set to an array
  const states = Array.from(new Set(centers?.map(center => center.state))).filter(state => isNullOrUndefinedOrEmpty(state) === false).sort();

  const stateFilter = useSelector((store: IStore) => store.app.stateFilter) || "all";

  const [selectedState, setSelectedState] = React.useState<string | undefined>(props.all ? "all" : undefined);

  useEffect(() => {
    if (props.all) {
      setSelectedState(stateFilter);
    } else {
      setSelectedState(stateFilter);
    }
  }, [stateFilter]);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchOrgCenters();
  }, [orgId]);

  const fetchOrgCenters = async () => {
    if (orgId !== undefined) {
      dispatch(OrganizationActions.getCenters.request({
        orgId: orgId
      }));
    }
  }

  const onSelectedValueChanged = (val: any) => {
    if (!states) {
      return
    }

    if (props.all) {
      dispatch(AppActions.setStateFilter(val));
    }
  }

  return (
    <View style={containerStyle.componentContainer}>
      <Text style={textStyle.subSectionText}>Select State</Text>
      <Select style={{ width: "95%" }} selectedValue={selectedState}
        accessibilityLabel="Choose state"
        placeholder="Choose state" _selectedItem={selectProps.selectedItemProps} mt={1} onValueChange={itemValue => onSelectedValueChanged(itemValue)}>
        {props.all && <Select.Item label={"All states"} value="all" />}
        {
          states
          .filter(state => state !== undefined && state !== "" && state !== null)
          .map((state, index) => {
            return <Select.Item key={index} label={state} value={state} />
          })
        }
      </Select>
    </View>
  );
};
