import React from 'react';
import { View, Text } from 'react-native';
import { Pressable, Box, HStack } from 'native-base';
import { listStyle } from 'src/styles/listStyle';
import { cardStyle } from 'src/styles/cardStyle';
import { useDispatch } from 'react-redux';
import { setCenterIdFilter } from 'src/redux/actions/appAction';
import { ICenter } from 'src/models/Center';
import StatisticsTextWithIcon from '../../StatisticsTextWithIcon';

interface CenterWithStatsListItemProps {
  item: ICenter;
}

export default function CenterWithStatsListItem({ item }: CenterWithStatsListItemProps) {
  const dispatch = useDispatch();

  const handlePress = () => {
    dispatch(setCenterIdFilter(item.centerId));
  };

  return (
    <Pressable onPress={handlePress} style={cardStyle.normalCard} rounded="8" overflow="hidden" borderWidth="1" borderColor="coolGray.300" shadow="3" bg="coolGray.100" p="4">
      <Box>
        <View style={listStyle.cardSection}>
          <View style={{ width: "70%" }}>
            <Text style={listStyle.largeTitle}>{item.addressDistrict ? `${item.name}, ${item.addressDistrict}` : item.name}</Text>
            <HStack
              space={8}
              mt={2}
              flexWrap="wrap">
              <StatisticsTextWithIcon
                statistic={`${item.stats?.number_of_trucks}`}
                text="No. of Trucks"
                icon="truck" />
              <StatisticsTextWithIcon
                statistic={`${item.stats?.total_quantity_in_quintals}`}
                text="Quintals Analysed"
                icon="weight" />
            </HStack>
          </View>
        </View>
      </Box>
    </Pressable>
  );
}
