import React from 'react';

import { Text, HStack } from 'native-base';
import { listStyle } from 'src/styles/listStyle';
import MaterialIconByName from '../MaterialIconByName';

interface StatisticsTextWithIconProps {
    text: String | undefined;
    statistic: string | undefined;
    icon: string;
}

export default function StatisticsTextWithIcon(props: StatisticsTextWithIconProps) {

    return <HStack
        style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
            alignSelf: "center",
        }}
        space={2}>
        <MaterialIconByName
            style={listStyle.largeIcon}
            name={props.icon} />
        <Text
            style={listStyle.largeSubTitleText}>
            {props.text || ""}
        </Text>
        <Text
            style={listStyle.largeSubTitleBoldText}>
            {props.statistic || ""}
        </Text>
    </HStack>
};