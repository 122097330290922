import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Box, HStack, Text } from 'native-base';
import MaterialIconByName from '../MaterialIconByName';

interface OrgStatisticsItemWithIconProps {
    title: string;
    value: string;
    subTitle?: string;
    icon?: string;
    color?: string;
}

export default function OrgStatisticsItemWithIcon(props: OrgStatisticsItemWithIconProps) {

    return (
        <Box style={[styles.container, {
            backgroundColor: props.color || 'darkseagreen'
        }]}>
            <View style={styles.statisticsView}>
                <Text style={styles.statisticsTitle}>{props.title}</Text>
            </View>
            <View style={styles.statisticsView}>
                <HStack
                    justifyContent={'center'}
                    alignItems={'center'}
                    space={4}>
                    <MaterialIconByName
                        size={'10'}
                        name={props.icon || 'human-male'}
                        color={'#fff'} />
                    <Text style={styles.statisticsText}>{props.value}</Text>
                </HStack>

            </View>
            <View style={styles.statisticsView}>
                <Text style={styles.statisticsSubTitle}>{props.subTitle}</Text>
            </View>
        </Box>
    );
};

const styles = StyleSheet.create({
    container: {
        margin: 8,
        padding: 16,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#ddd',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
        elevation: 1,
        width: '100%',
        maxWidth: 200,
        backgroundColor: 'darkseagreen',
        alignItems: 'center',
    },
    statisticsView: {
        margin: 4
    },
    statisticsTitle: {
        fontSize: 20,
        textAlign: 'left',
        color: '#fff'
    },
    statisticsSubTitle: {
        fontSize: 14,
        textAlign: 'center',
        color: '#555',
    },
    statisticsText: {
        fontSize: 48,
        textAlign: 'center',
        color: '#fff'
    },
});