import * as React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import SurveyorListComponent from "src/components/list/SurveyorListComponent"
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import OrgJobHistoryList from '../list/OrgJobHistoryList';
import OrgSummaryComponent from '../OrgSummaryComponent';

const Tab = createMaterialTopTabNavigator();

export default function OrgDashboardTabs({ navigation }) {

    return (
        <Tab.Navigator
            screenOptions={() => ({
                tabBarActiveTintColor: 'black',
                tabBarInactiveTintColor: 'gray',
                tabBarLabelStyle: { fontSize: 8 },
            })}>
            <Tab.Screen
                name="Summary"
                component={OrgSummaryComponent} options={{
                    tabBarLabel: 'SUMMARY',
                    tabBarIcon: ({ color }) => (
                        <MaterialCommunityIcons name="information" color={color} size={24} />
                    ),
                }} />
            <Tab.Screen
                name="Surveyors"
                component={SurveyorListComponent} options={{
                    tabBarLabel: 'SURVEYORS',
                    tabBarIcon: ({ color }) => (
                        <MaterialCommunityIcons name="account-supervisor-outline" color={color} size={24} />
                    ),
                }} />
            <Tab.Screen
                name="History"
                component={OrgJobHistoryList} options={{
                    tabBarLabel: 'ALL READINGS',
                    tabBarIcon: ({ color }) => (
                        <MaterialCommunityIcons name="clipboard-text-clock" color={color} size={24} />
                    ),
                }} />
        </Tab.Navigator>
    );
}