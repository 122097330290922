import React, { useEffect } from 'react';
import { IStore } from "../../redux/reducers";
import { useSelector } from "react-redux";
import { Table, TableWrapper, Cell } from 'react-native-table-component';
import { View } from 'react-native';
import { JobStatus } from '../../models';
import { tableStyle } from 'src/styles/tableStyle';
import { RICE_GRAIN_ID } from 'src/constants';

export default function GrainTopLevelAnalysisTable() {

  const job = useSelector((store: IStore) => store.job.jobStatus.job);
  const jobStatus = useSelector((store: IStore) => store.job.currentJobStatus);
  const orgId = useSelector((store: IStore) => store.user.userProfile.response?.org.orgId);
  const whitenessIndexEnabled = useSelector((store: IStore) => store.user.userProfile.response?.settings.whiteness_index_enabled) || false;
  const goodAndRejectPercentEnabled = useSelector((store: IStore) => store.user.userProfile.response?.settings.good_and_reject_percent_enabled) || false;

  const [state, setState] = React.useState({
    kernelCount: 0,
    tableHead: ['Total Kernels'],
    tableData: [
      ['0'],
    ]
  });

  useEffect(() => {
    updateTopLevelAnalysis();
  }, [job]);

  const updateTopLevelAnalysis = () => {
    if (jobStatus == JobStatus.JOB_COMPLETED && job) {

      var head = ['Total Kernels'];
      var result = [`${beautify(job.results?.grain_count)}`]

      if (whitenessIndexEnabled &&
        job.grainId === RICE_GRAIN_ID
        && job.results?.whiteness_index !== undefined) {
        head.push('Whiteness Index');
        result.push(`${beautify(job.results?.whiteness_index)}`);
      }

      if (goodAndRejectPercentEnabled &&
        job.results?.good_grain_percent !== undefined &&
        job.results?.rejection_percent !== undefined) {
        head.push('Good Grains');
        head.push('Rejected Grains');
        result.push(`${beautify(job.results?.good_grain_percent)} %`);
        result.push(`${beautify(job.results?.rejection_percent)} %`);
      }

      setState({
        ...state,
        tableHead: head,
        tableData: [result]
      });
    }
  }

  const beautify = (data: any) => {
    if (!isNaN(parseFloat(data)) && !isNaN(data - 0)) {
      let floatData = parseFloat(data);
      if (Math.ceil(floatData) == Math.floor(floatData)) {
        return data;
      } else {
        return floatData.toFixed(2);
      }
    } else {
      return 0;
    }
  }


  return (
    <View>
      <Table borderStyle={tableStyle.mainBorder}>
        <TableWrapper style={tableStyle.mainHead}>
          {
            state.tableHead.map((cellData, index) => (
              <Cell key={index} data={cellData} textStyle={tableStyle.mainCellHead} />
            ))
          }
        </TableWrapper>
        {
          state.tableData.map((rowData, index) => (
            <TableWrapper key={index} style={tableStyle.mainRow}>
              {
                rowData.map((cellData, cellIndex) => (
                  <Cell key={cellIndex} data={beautify(cellData)} textStyle={tableStyle.mainCellText} />
                ))
              }
            </TableWrapper>
          ))
        }
      </Table>
    </View>
  );
};