import React from 'react';
import { View, Text } from 'react-native';
import { Pressable, Box, HStack } from 'native-base';
import { listStyle } from 'src/styles/listStyle';
import { cardStyle } from 'src/styles/cardStyle';
import { useDispatch } from 'react-redux';
import { setDistrictFilter } from 'src/redux/actions/appAction';
import { IDistrict } from 'src/models/Center';
import StatisticsTextWithIcon from '../../StatisticsTextWithIcon';

interface CenterDistrictListItemProps {
  item: IDistrict;
}

export default function CenterDistrictListItem({ item }: CenterDistrictListItemProps) {
  const dispatch = useDispatch();

  const handlePress = () => {
    dispatch(setDistrictFilter(item.district));
  };

  return (
    <Pressable onPress={handlePress}
      style={cardStyle.normalCard}
      rounded="8" overflow="hidden"
      borderWidth="1"
      borderColor="coolGray.300"
      shadow="3"
      bg="coolGray.100" p="4">
      <Box>
        <View style={listStyle.cardSection}>
          <View style={{ width: "70%" }}>
            <Text style={listStyle.largeTitle}>{item.district}</Text>
            <HStack
              space={8}
              mt={2}
              flexWrap="wrap">
              <StatisticsTextWithIcon
                statistic={`${item.total_warehouses}`}
                text="No. of Warehouses / Centers"
                icon="store" />
              <StatisticsTextWithIcon
                statistic={`${item.number_of_trucks}`}
                text="No. of Trucks"
                icon="truck" />
              <StatisticsTextWithIcon
                statistic={`${item.total_quantity_in_quintals || 0}`}
                text="Quintals Analysed"
                icon="weight" />
            </HStack>
          </View>
        </View>
      </Box>
    </Pressable>
  );
}
