import React from 'react';
import { useSelector } from "react-redux";
import { VStack, View } from 'native-base';
import CenterDropdown from '../dropdown/CenterDropdown';
import SearchComponent from '../SearchComponent';
import GrainTypeDropdown from '../dropdown/GrainTypeDropdown';
import DateRangePicker from '../DateRangePicker';
import { IStore } from 'src/redux/reducers';
import { ISearchQuery, IDateRange } from 'src/models';
import StateDropdown from 'src/components/dropdown/StateDropDown';
import DistrictDropdown from 'src/components/dropdown/DistrictDropDown';
import DateRangePickerModal from '../DateRangePickerModal';

interface IJobFiltersProps {
    useModalForCalendar?: boolean;
    enableSearchBy?: string[];
}

export default function JobFilters(props: IJobFiltersProps) {

    const [numberOfFiltersApplied, setNumberOfFiltersApplied] = React.useState(0);

    const currentStack = useSelector((store: IStore) => store.app.currentStack);

    const grainIdFilter = useSelector((store: IStore) => store.app.grainIdFilter) || "all";
    const searchQuery: ISearchQuery | undefined = useSelector((store: IStore) => store.app.searchQuery);
    const dateRange: IDateRange | undefined = useSelector((store: IStore) => store.app.dateRange);
    const centerIdFilter = useSelector((store: IStore) => store.app.centerIdFilter) || "all";

    const getNumberOfFiltersApplied = () => {
        let count = 0;
        if (grainIdFilter !== undefined
            && grainIdFilter !== ""
            && grainIdFilter !== "0"
            && grainIdFilter !== "all") {
            count++;
        }
        if (searchQuery !== undefined && searchQuery?.query !== undefined && searchQuery?.query !== "") {
            count++;
        }
        if (dateRange !== undefined && dateRange?.from !== undefined && dateRange?.to !== undefined) {
            count++;
        }

        if (centerIdFilter !== ""
            && centerIdFilter !== undefined
            && centerIdFilter !== "all") {
            count++;
        }
        return count;
    }

    React.useEffect(() => {
        setNumberOfFiltersApplied(getNumberOfFiltersApplied());
    }, [grainIdFilter, searchQuery, dateRange]);


    return (
        <View>
            {(currentStack === 'orgSuperAdmin') &&
                <StateDropdown all={true} />
            }
            {(currentStack === 'orgSuperAdmin') &&
                <DistrictDropdown all={true} />
            }
            {(currentStack === 'orgAdmin' || currentStack === 'orgSuperAdmin') &&
                <CenterDropdown all={true} />}
            <GrainTypeDropdown all={true} />

            <SearchComponent enableSearchBy={props.enableSearchBy} />
            {
                props.useModalForCalendar ? <DateRangePickerModal /> : <DateRangePicker />
            }
        </View>
    );
};