import React from 'react';
import { IStore } from "../../redux/reducers";
import { useSelector } from "react-redux";
import { View } from 'react-native';
import { isNative } from '../../utils/utils'
import CustomAlertDialog from '../CustomAlertDialog';
import { Button, HStack } from 'native-base';
import MaterialIconByName from '../MaterialIconByName';

export default function InfoStatusBar({ navigation }) {

    const grainTypes = useSelector((store: IStore) => store.user.userProfile.response?.grains);
    const grainId: string = useSelector((store: IStore) => store.grainType.grainId);

    const rpiStatus = useSelector((store: IStore) => store.bluetooth.rpiStatus);
    const rpiConnected = useSelector((store: IStore) => store.bluetooth.connectedRpi.connectedDevice);

    const [grainName, setGrainName] = React.useState<string | undefined>(undefined);
    const [visible, setVisible] = React.useState(false);

    const showDialog = () => setVisible(true);

    const hideDialog = () => setVisible(false);


    const isWifiConnected = () => {
        if (rpiStatus === undefined || rpiStatus.status === undefined) {
            return false;
        }

        return rpiStatus.status.wifiStatus === "CONNECTED";
    }

    React.useEffect(() => {
        if (grainTypes) {
            const grain = grainTypes.find((item) => item.grainId === grainId);
            if (grain) {
                setGrainName(grain.name);
            }
        }
    }, [grainTypes, grainId]);

    return (
        <View>
            <CustomAlertDialog
                title='IoT device not connected'
                description='GRAMs IoT device is not connected via bluetooth. Click continue to configure it.'
                cancelText='Cancel'
                confirmText='Continue'
                isOpen={visible}
                onCancelClicked={hideDialog}
                onConfirmClicked={() => {
                    hideDialog();
                    navigation.push('ConnectToRpi', {
                        returnBackTo: 'Home',
                    })
                }}
            />
            <HStack style={{
                padding: 4,
                flexDirection: 'row',
                justifyContent: 'center',
                marginLeft: 12,
                marginRight: 12,
                width: '100%',
            }} space={2}>
                {
                    isNative() && <Button variant='outline'
                        colorScheme={rpiConnected !== undefined ? "green" : "red"}
                        style={{
                            borderColor: rpiConnected !== undefined ? "green" : "red",
                            width: 100,
                            height: 42,
                        }}
                        leftIcon={<MaterialIconByName name={rpiConnected !== undefined ? "bluetooth-connect" : "bluetooth-off"} />}
                        onPress={() =>
                            navigation.navigate('ConnectToRpi', {
                                returnBackTo: 'Home',
                            })
                        } >BT</Button>
                }
                {
                    isNative() && <Button variant='outline'
                        colorScheme={isWifiConnected() ? "green" : "red"}
                        style={{ borderColor: isWifiConnected() ? "green" : "red", height: 42, width: 100 }}
                        leftIcon={<MaterialIconByName name={isWifiConnected() ? "wifi" : "wifi-off"} />}
                        onPress={() => {
                            if (rpiConnected !== undefined) {
                                navigation.navigate('ConfigureRpiWifi', {
                                    returnBackTo: 'Home',
                                })
                            } else {
                                showDialog()
                            }
                        }
                        } >WiFi</Button>
                }

                <Button variant='outline'
                    style={{
                        borderColor: "green",
                        height: 42,
                        width: 110,
                    }}
                    colorScheme={"green"}
                    leftIcon={<MaterialIconByName name="barley" />}
                    onPress={() =>
                        navigation.navigate('ChooseGrainProfile', {})
                    } >{grainName}</Button>
            </HStack>
        </View>
    );
};

