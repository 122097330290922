import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { launchCamera, launchImageLibrary } from 'react-native-image-picker';
import { VStack, Modal, View, Text, Button, Center, FormControl } from 'native-base';
import { textStyle } from 'src/styles/textStyle';
import MaterialIconByName from '../MaterialIconByName';
import { UPLOAD_TIMEOUT } from 'src/constants';
import { IStore } from 'src/redux/reducers';

import * as UploadAction from '../../redux/actions/uploadAction';

interface JobInfoImageUploadComponentProps {
  jobId: string | undefined;
  displayName: string;
  fieldName: string;
  onSuccess?: (s3Path: string) => void;
}

export default function JobInfoImageUploadComponent(props: JobInfoImageUploadComponentProps) {

  const key = `${props.jobId}_${props.fieldName}`

  const uploadSuccessful = useSelector((store: IStore) => store.upload.uploadJobInfoImage[key]?.success);
  const objectS3Path = useSelector((store: IStore) => store.upload.uploadJobInfoImage[key]?.s3Path);

  const [isModalVisible, setModalVisible] = React.useState(false);
  const [waiting, setWaiting] = React.useState(false);

  const dispatch = useDispatch();

  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  React.useEffect(() => {
    if (uploadSuccessful === true) {
      setWaiting(false)
      if (props.onSuccess !== undefined) {
        props.onSuccess(objectS3Path || "")
      }
    }
  }, [uploadSuccessful])

  const imageUploadButtonClick = () => {
    launchImageLibrary({
      selectionLimit: 0,
      mediaType: 'photo',
      includeBase64: false,
    }, (data) => setResponseAndUploadFile(data, "image"))
  }

  const imageCaptureButtonClick = () => {
    launchCamera({
      mediaType: 'photo',
      includeBase64: false,
    }, (data) => setResponseAndUploadFile(data, "camera"))
  }

  const setResponseAndUploadFile = async (data: any, source = "image") => {
    if (data?.assets === undefined ||
      data?.assets === null ||
      data?.assets.length === 0
      || props.jobId === undefined || props.jobId === null) {
      console.log("Invalid data object")
      return
    }
    setWaiting(true)
    setTimeout(() => {
      setWaiting(false)
    }, UPLOAD_TIMEOUT)

    dispatch(UploadAction.uploadJobInfoImage.request({
      jobId: props.jobId,
      isBase64: false,
      dataObject: data?.assets[0],
      fieldName: props.fieldName,
    }));

    hideModal();
  }

  return (
    <View>
      <Modal
        size={"xl"}
        isOpen={isModalVisible}
        onClose={hideModal}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>{`Upload ${props.displayName}`}</Modal.Header>
          <Modal.Body>
            <VStack
              margin={4}
              space={4}>
              <Button
                leftIcon={<MaterialIconByName name="camera" />}
                onPress={imageCaptureButtonClick}>Take Picture</Button>
              <Button
                leftIcon={<MaterialIconByName name="image" />}
                onPress={imageUploadButtonClick}>Choose From Gallery</Button>
            </VStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>
      <VStack space={3}>
        <FormControl.Label _text={textStyle.inputLabel}>{props.displayName}</FormControl.Label>
        <Button
          isLoading={waiting}
          leftIcon={<MaterialIconByName name={uploadSuccessful ? "check" : "image"} />}
          isLoadingText={`Uploading ${props.displayName}...`}
          disabled={waiting || props.jobId === undefined || props.jobId === null}
          variant={"outline"}
          onPress={showModal}>{
            uploadSuccessful ? `${props.displayName} Uploaded` : `Upload ${props.displayName}`
          }</Button>
      </VStack>
    </View>

  );
};