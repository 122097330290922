import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../redux/reducers";
import * as OrgActions from '../../../redux/actions/orgAction';
import { View, FlatList, RefreshControl, ListRenderItem } from 'react-native';
import SurveyorListItem from '../../listItem/SurveyorListItem';
import { Button } from 'native-base';
import { listStyle } from 'src/styles/listStyle';
import { containerStyle } from 'src/styles/containerStyle';
import { ISurveyor } from '../../../models/Surveyor';

export default function SurveyorListComponent() {
  const [refreshing, setRefreshing] = React.useState(false);
  const surveyors = useSelector((store: IStore) => store.org.surveyors);
  const orgId = useSelector((store: IStore) => store.user.userProfile.response?.org.orgId);

  const dispatch = useDispatch();

  useEffect(() => {
    if (orgId) {
      dispatch(OrgActions.fetchSurveyors.request(orgId));
    }
  }, [orgId]);

  const fetchSurveyors = async () => {
    if (orgId) {
      dispatch(OrgActions.fetchSurveyors.request(orgId));
    }
  };

  const renderItem: ListRenderItem<ISurveyor> = ({ item }) => (
    <SurveyorListItem item={item} />
  );

  return (
    <View style={containerStyle.listContainer}>
      <FlatList
        data={surveyors}
        renderItem={renderItem}
        keyExtractor={item => item.surveyorId}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={fetchSurveyors}
          />
        }
        ListFooterComponent={
          <View style={listStyle.footer}>
            <Button onPress={fetchSurveyors} isLoading={refreshing} variant="subtle">
              Load more
            </Button>
          </View>
        }
      />
    </View>
  );
}
