import React, { useEffect } from 'react';
import { Table, TableWrapper, Cell } from 'react-native-table-component';
import { View } from 'react-native';
import * as UserActions from '../../redux/actions/userAction';
import csv from 'csvtojson';
import { Text, Input } from 'native-base';
import { Button } from 'native-base';
import { useDispatch, useSelector } from "react-redux";
import { textStyle } from 'src/styles/textStyle';
import { tableStyle } from 'src/styles/tableStyle';

export default function ParticleDistributionTable(props) {

  const dispatch = useDispatch();

  const colorMode = useSelector((store) => store.app.colorMode);

  const username = useSelector((store) => store.user.userProfile.response?.username);
  const orgId = useSelector((store) => store.user.userProfile.response?.org.orgId);

  const particleDistSettings = useSelector((store) => store.user.userSettings);

  const [saveSettingsVisible, setSaveSettingsVisible] = React.useState(false);
  const [updatedSettings, setUpdatedSettings] = React.useState(undefined);

  const [state, setState] = React.useState({
    tableHead: ['Name', 'Lower Bound', 'Upper Bound', 'Count', '% by count', '% by weight'],
    tableData: [
      ['Range 1', 0.0, 3.0, 0, 0, 0],
      ['Range 2', 3.0, 6.0, 0, 0, 0]
    ],
    indiGrainData: [[]]
  });

  useEffect(() => {
    fetchUserSettings();
    fetchIndiGrainData(props.csvUrl);
  }, []);

  useEffect(() => {
    if (particleDistSettings === undefined
      || particleDistSettings[`particle_distribution_${props.colIndex}`] === undefined) {
      return
    }

    const rowDist = getRowDistFromUserSettings(particleDistSettings[`particle_distribution_${props.colIndex}`]);
    getParticleDistribution(rowDist);
  }, [particleDistSettings]);

  useEffect(() => {
    let rowDist = getRowDist();
    getParticleDistribution(rowDist);
  }, [state.indiGrainData]);

  const fetchUserSettings = async () => {
    dispatch(UserActions.getUserSettings.request({
      "username": username,
      "grainId": props.grainId,
      "profileId": props.profileId,
      "orgId": orgId,
      "settingName": `particle_distribution_${props.colIndex}`
    }));
  }

  const updateParticleDistributionSettings = async () => {
    const newParticleDistSetting = [];

    for (let i = 0; i < updatedSettings.length; i++) {
      newParticleDistSetting.push([parseFloat(updatedSettings[i][1]),
      parseFloat(updatedSettings[i][2]),
      updatedSettings[i][0] || `Range ${i + 1}`])
    }

    dispatch(UserActions.updateUserSettings.request({
      "username": username,
      "grainId": props.grainId,
      "profileId": props.profileId,
      "newSetting": newParticleDistSetting,
      "settingName": `particle_distribution_${props.colIndex}`
    }));

    setSaveSettingsVisible(false);
  }

  const fetchIndiGrainData = async (csvUrl) => {
    if (csvUrl === undefined) {
      return
    }

    return fetch(csvUrl)
      .then(async (response) => {
        const resp = await response.text();
        csv({
          noheader: true,
          output: "csv"
        }).fromString(resp)
          .then((csvRow) => {
            setState(state => ({
              ...state,
              indiGrainData: csvRow.slice(1)
            }));
          })
      })
      .catch((error) => {
        console.error("some error occurred", error);
      });
  }

  const getRowDistFromUserSettings = (particleDistSettings) => {
    let tData = state.tableData
    let dist = [];
    for (let i = 0; i < tData.length; i++) {
      dist.push([
        particleDistSettings[i][2] || `Range ${i + 1}`,
        particleDistSettings[i][0], particleDistSettings[i][1]])
    }

    for (let i = 0; i < dist.length; i++) {
      dist[i][3] = 0;
      dist[i][4] = 0;
      dist[i][5] = 0;
    }

    return dist;
  }

  const getRowDistOnChange = (rowIndex, colIndex, newVal) => {
    let tData = state.tableData
    let dist = [];
    for (let i = 0; i < tData.length; i++) {
      dist.push([tData[i][0], tData[i][1], tData[i][2]])
    }

    if (colIndex === 1 || colIndex === 2) {
      const validated = newVal.match(/^(\d*\.{0,1}\d{0,2}$)/)
      if (validated) {
        dist[rowIndex][colIndex] = newVal;
      }
    } else {
      dist[rowIndex][colIndex] = newVal;
    }

    for (let i = 0; i < dist.length; i++) {
      dist[i][3] = 0;
      dist[i][4] = 0;
      dist[i][5] = 0;
    }

    return dist;
  }

  const beautify = (data) => {
    if (!isNaN(parseFloat(data)) && !isNaN(data - 0)) {
      let floatData = parseFloat(data);
      if (Math.ceil(floatData) == Math.floor(floatData)) {
        return data;
      } else {
        return floatData.toFixed(2);
      }
    } else {
      return data;
    }
  }

  const getRowDist = () => {
    let dist = [];
    for (let i = props.lower; i < props.upper; i += props.stepSize) {
      dist.push([`Range ${i + 1}`, i, i + props.stepSize])
    }

    for (let i = 0; i < dist.length; i++) {
      dist[i][3] = 0;
      dist[i][4] = 0;
      dist[i][5] = 0;
    }
    return dist;
  }

  const getParticleDistribution = (rowDist) => {
    if (state.indiGrainData.length == undefined || state.indiGrainData.length == 0) {
      return;
    }

    let dist = rowDist;
    let grainWiseData = state.indiGrainData;
    let totalCount = state.indiGrainData.length;
    let totalVolume = 0;

    grainWiseData.forEach((row) => {
      const metric = parseFloat(row[props.colIndex]);
      const volume = parseFloat(row[4]);
      for (let i = 0; i < dist.length; i++) {
        let interval = dist[i];
        if (metric >= interval[1] && metric < interval[2]) {
          dist[i][3] += 1;
          dist[i][5] += volume;
        }
      }
      totalVolume += volume;
    })

    for (let i = 0; i < dist.length; i++) {
      dist[i][4] = (dist[i][3] / totalCount) * 100;
      dist[i][5] = (dist[i][5] / totalVolume) * 100;
    }

    setState({
      ...state,
      tableData: dist
    })
  }

  const element = (data, rowIndex, colIndex) => (
    <Input selectTextOnFocus
      scrollEnabled={false}
      key={`${rowIndex},${colIndex}`}
      value={
        colIndex === 0 ? data
          : beautify(data)
      }
      style={[{ color: colorMode == "dark" ? "white" : "black" }]}
      keyboardType={colIndex === 1 || colIndex === 2 ? "decimal-pad" : "default"}
      onChangeText={(text) => {
        let rowDist = getRowDistOnChange(rowIndex, colIndex, text);
        setSaveSettingsVisible(true);
        setUpdatedSettings(rowDist);
        getParticleDistribution(rowDist);
      }} />
  );

  const textElement = (data) => (
    <Text style={[tableStyle.cellText, {
      color: colorMode === 'dark' ? 'white' : 'black'
    }]}>{data}</Text>
  );

  return (
    <View style={tableStyle.tableContainer}>
      <View style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <Text style={textStyle.tableTitleText}>{props.title}</Text>
        {saveSettingsVisible && <Button variant={"link"} onPress={async () => {
          await updateParticleDistributionSettings()
        }}>Save settings</Button>}
      </View>
      <Table borderStyle={tableStyle.border}>
        <TableWrapper style={tableStyle.head}>
          {
            state.tableHead.map((cellData, index) => (
              <Cell key={index} data={cellData} textStyle={tableStyle.cellText} />
            ))
          }
        </TableWrapper>
        {
          state.tableData.map((rowData, index) => (
            <TableWrapper key={index} style={tableStyle.row}>
              {
                rowData.map((cellData, cellIndex) => (
                  <Cell key={cellIndex} data={cellIndex === 0
                    || cellIndex === 1
                    || cellIndex === 2 ? element(cellData, index, cellIndex)
                    : textElement(beautify(cellData))} textStyle={tableStyle.cellText} />
                ))
              }
            </TableWrapper>
          ))
        }
      </Table>
    </View>
  );
};
