import React, {  } from 'react';
import { View, Image } from 'react-native';
import { Text } from 'native-base';
import { containerStyle } from 'src/styles/containerStyle';
import { imageStyle } from 'src/styles/imageStyle';

interface InweonLogoProps {
    title: string;
}

export default function InweonLogo(props: InweonLogoProps) {

    return (
        <View style={containerStyle.logoContainer}>
            <Image
                style={imageStyle.inweonLogo}
                source={{ uri: "https://grams-images-bucket.s3.us-west-2.amazonaws.com/grams/grams_logo.png" }}
            />
            <Text style={{
                fontSize: 20,
                flexWrap: 'wrap'
            }}>
                {props.title || "Welcome to Grams"}
            </Text>
        </View>
    );
}