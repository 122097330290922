import React from 'react';
import { Text } from 'react-native';
import { Box, Button, HStack, ScrollView, Image } from 'native-base';
import { useDispatch, useSelector } from 'react-redux';
import JobFilters from '../JobFilters';
import { IStore } from 'src/redux/reducers';
import * as AppAction from '../../redux/actions/appAction';
import MaterialIconByName from '../MaterialIconByName';
import { imageStyle } from 'src/styles/imageStyle';

interface OrgSuperAdminJobFiltersComponentProps {

}

export default function OrgSuperAdminJobFiltersComponent({ }:
  OrgSuperAdminJobFiltersComponentProps) {
  const dispatch = useDispatch();

  const [numberOfFiltersApplied, setNumberOfFiltersApplied] = React.useState(0);

  const grainIdFilter = useSelector((store: IStore) => store.app.grainIdFilter);
  const centerIdFilter = useSelector((store: IStore) => store.app.centerIdFilter);
  const stateFilter = useSelector((store: IStore) => store.app.stateFilter);
  const districtFilter = useSelector((store: IStore) => store.app.districtFilter);
  const searchQuery = useSelector((store: IStore) => store.app.searchQuery);
  const dateRange = useSelector((store: IStore) => store.app.dateRange);

  const getNumberOfFiltersApplied = () => {
    let count = 0;
    if (grainIdFilter !== undefined
      && grainIdFilter !== ""
      && grainIdFilter !== "0"
      && grainIdFilter !== "all") {
      count++;
    }
    if (searchQuery !== undefined && searchQuery?.query !== undefined && searchQuery?.query !== "") {
      count++;
    }
    if (dateRange !== undefined && dateRange?.from !== undefined && dateRange?.to !== undefined) {
      count++;
    }

    if (centerIdFilter !== ""
      && centerIdFilter !== undefined
      && centerIdFilter !== "all") {
      count++;
    }

    if (stateFilter !== ""
      && stateFilter !== undefined
      && stateFilter !== "all") {
      count++;
    }

    if (districtFilter !== ""
      && districtFilter !== undefined
      && districtFilter !== "all") {
      count++;
    }

    return count;
  }

  const handleResetFilters = async () => {
    dispatch(AppAction.resetFilters());
  }

  React.useEffect(() => {
    setNumberOfFiltersApplied(getNumberOfFiltersApplied());
  }, [grainIdFilter, searchQuery, dateRange, centerIdFilter, stateFilter, districtFilter]);

  return (
    <Box style={{
      maxWidth: '30%',
      padding: 12,
    }}>
      <ScrollView>
        <Image
          style={imageStyle.inweonLogoForFilters}
          source={{ uri: "https://grams-images-bucket.s3.us-west-2.amazonaws.com/grams/grams_logo.png" }}
        />

        <HStack
          alignContent={"center"}
          alignItems={"center"}
          mt={4}
          justifyContent={"space-between"}>
          <Text style={{
            fontSize: 16,
            fontWeight: "bold"
          }}>{`Filters`}</Text>
          <Button
            size="xs"
            variant="subtle"
            style={{
              backgroundColor: `${numberOfFiltersApplied > 0 ? '#DF8A8A' : '#f0f0f0'}`,
            }}
            endIcon={<MaterialIconByName name="close" />}
            onPress={handleResetFilters}>
            {numberOfFiltersApplied > 0
              ? `Reset (${numberOfFiltersApplied} ${numberOfFiltersApplied === 1 ? 'filter'
                : 'filters'})` : "Reset"}
          </Button>
        </HStack>
        <JobFilters
          enableSearchBy={["farmerCode"]}
          useModalForCalendar={true} />
      </ScrollView>
    </Box>
  );
}
