import React from 'react';
import { View, Text } from 'react-native';
import { Pressable, Box, HStack } from 'native-base';
import { listStyle } from 'src/styles/listStyle';
import { cardStyle } from 'src/styles/cardStyle';
import { ITruck } from 'src/models/Center';
import StatisticsTextWithIcon from '../../StatisticsTextWithIcon';
import { getFormattedDate } from 'src/utils/jobUtils';

interface CenterTruckListItemProps {
  item: ITruck;
}

export default function CenterTruckListItem({ item }: CenterTruckListItemProps) {
  return (
    <Pressable style={cardStyle.normalCard} rounded="8" overflow="hidden" borderWidth="1" borderColor="coolGray.300" shadow="3" bg="coolGray.100" p="4">
      <Box>
        <View style={listStyle.cardSection}>
          <View style={{ width: "70%" }}>
            <Text style={listStyle.largeTitle}>{item.truckID}</Text>
            <HStack
              space={8}
              mt={2}
              flexWrap="wrap">
              <StatisticsTextWithIcon
                statistic={`${item.quintals}`}
                text="Quintals analyzed:"
                icon="weight" />
              <StatisticsTextWithIcon
                statistic={`${item.farmerCode}`}
                text="Farmer Code:"
                icon="human-male" />
              <StatisticsTextWithIcon
                statistic={`${getFormattedDate(item.timestamp / 1000)}`}
                text="Sampled at:"
                icon="clock" />
            </HStack>
          </View>
        </View>
      </Box>
    </Pressable>
  );
}
