import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../redux/reducers";
import * as OrgActions from '../../../redux/actions/orgAction';
import { View, RefreshControl, TouchableOpacity, ListRenderItem } from 'react-native';
import { Box, FlatList, Text } from 'native-base';
import { containerStyle } from 'src/styles/containerStyle';
import { IDistrict } from 'src/models/Center';
import CenterDistrictListItem from '../../listItem/CenterDistrictListItem';
import { setDistrictFilter } from 'src/redux/actions/appAction';
import ProgressBar from '../../ProgressBar';

export default function CenterDistrictsListComponent({ navigation }) {
  const [refreshing, setRefreshing] = React.useState(false);

  const isFetchingDistricts = useSelector((store: IStore) => store.org?.centerDistricts?.isFetching) || false;

  const districts = useSelector((store: IStore) => store.org?.centerDistricts?.districts) || [];
  const orgId = useSelector((store: IStore) => store.user.userProfile.response?.org.orgId);
  const stateFilter = useSelector((store: IStore) => store.app.stateFilter) || "all";
  const grainIdFilter = useSelector((store: IStore) => store.app.grainIdFilter) || "all";
  const dateRange = useSelector((store: IStore) => store.app.dateRange);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchCenterDistricts();
  }, [orgId, stateFilter, grainIdFilter, dateRange]);

  const fetchCenterDistricts = async () => {
    if (orgId) {
      dispatch(OrgActions.getCenterDistricts.request({
        orgId: orgId,
        state: stateFilter || undefined,
        grainId: grainIdFilter || undefined,
        startDate: dateRange?.from || undefined,
        endDate: dateRange?.to || undefined,
      }));
    }
  };
  const handleDistrictSelect = (district: string) => {
    dispatch(setDistrictFilter(district));
  };

  const renderItem: ListRenderItem<IDistrict> = ({ item }) => (
    <TouchableOpacity onPress={() => handleDistrictSelect(item.district)}>
      <CenterDistrictListItem item={item} />
    </TouchableOpacity>
  );

  return (
    <View style={containerStyle.listContainer}>
      <FlatList
        data={districts}
        renderItem={renderItem}
        keyExtractor={item => item.district}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={fetchCenterDistricts}
          />
        }
        ListEmptyComponent={
          isFetchingDistricts ? <View style={{
            flex: 1,
            padding: 48,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <ProgressBar
              progressText="Loading..."
              isVisibile={true}
              withoutOverlay={true} />
          </View> : <Box style={{
            flex: 1,
            padding: 48,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Text>No districts found for the selected filters</Text>
          </Box>
        }
      />
    </View>
  );
}
