import React, { useEffect } from 'react';
import * as OrganizationActions from "../../../redux/actions/orgAction";
import * as AppActions from "../../../redux/actions/appAction";
import { View } from 'react-native';
import { IStore } from "../../../redux/reducers";
import { useSelector, useDispatch } from "react-redux";
import { Select, Text } from "native-base";
import { containerStyle } from 'src/styles/containerStyle';
import { textStyle } from 'src/styles/textStyle';
import { selectProps } from 'src/styles/selectProps';
import { isNullOrUndefinedOrEmpty } from 'src/utils/utils';

export interface ICenterDropdownProps {
  all?: boolean; // if true, show "All centers" option and refers to Filter modal
}

export default function CenterDropdown(props: ICenterDropdownProps) {

  const centers = useSelector((store: IStore) => store.org.centers.centers);

  const stateFilter = useSelector((store: IStore) => store.app.stateFilter) || "all";
  const districtFilter = useSelector((store: IStore) => store.app.districtFilter) || "all";

  console.log("centers", centers);
  const centerId = useSelector((store: IStore) => store.user.userProfile.response?.center.centerId);
  const orgId = useSelector((store: IStore) => store.user.userProfile.response?.org.orgId);

  const centerIdFilter = useSelector((store: IStore) => store.app.centerIdFilter) || "all";

  const [selectedCenterId, setSelectedCenterId] = React.useState<string | undefined>(props.all ? "all" : undefined);

  useEffect(() => {
    if (props.all) {
      setSelectedCenterId(centerIdFilter)
    } else {
      setSelectedCenterId(centerId)
    }
  }, [centerIdFilter, centerId]);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchOrgCenters();
  }, [orgId]);

  const fetchOrgCenters = async () => {
    if (orgId !== undefined) {
      dispatch(OrganizationActions.getCenters.request({
        orgId: orgId
      }));
    }
  }

  const onSelectedValueChanged = (val: any) => {
    console.log("onSelectedValueChanged", val, centers, props.all);
    if (!centers) {
      return
    }

    if (props.all) {
      dispatch(AppActions.setCenterIdFilter(val));
    } else {
      dispatch(OrganizationActions.setCenterId(val));
    }
  }

  const filteredCenters = centers?.filter(center => {
    if (isNullOrUndefinedOrEmpty(center.addressDistrict)
      || isNullOrUndefinedOrEmpty(center.state)) {
      return true;
    }

    return (stateFilter === "all" || center.state === stateFilter) &&
      (districtFilter === "all" || center.addressDistrict === districtFilter);
  });

  return (
    <View style={containerStyle.componentContainer}>
      <Text style={textStyle.subSectionText}>Select Center</Text>
      <Select style={{
        width: "95%"
      }} selectedValue={selectedCenterId}
        accessibilityLabel="Choose center"
        placeholder="Choose center" _selectedItem={selectProps.selectedItemProps} mt={1} onValueChange={itemValue => onSelectedValueChanged(itemValue)}>
        {props.all && <Select.Item label={"All centers"} value="all" />}
        {
          filteredCenters?.map((center) => {
            return (
              <Select.Item
                key={center['centerId']}
                label={center['name']}
                value={center['centerId']}
              />
            );
          })
        }
      </Select>
    </View>
  );
};