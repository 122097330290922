import React, { useEffect } from 'react';
import { ScrollView } from 'react-native';
import { Box, Button, FormControl, HStack, IconButton, Input, Pressable, Text, VStack } from "native-base";
import * as JobAction from '../../redux/actions/jobAction';

import { useSelector, useDispatch } from "react-redux";
import { IStore } from '../../redux/reducers';
import { inputStyle } from 'src/styles/inputStyle';
import { containerStyle } from 'src/styles/containerStyle';
import Collapsible from 'react-native-collapsible';
import { InfoField } from 'src/models';
import { textStyle } from 'src/styles/textStyle';
import { buttonStyle } from 'src/styles/buttonStyle';
import MaterialIconByName from '../MaterialIconByName';
import { getMappedFieldName } from 'src/utils/surveyorUtils';
import JobInfoImageUploadComponent from '../JobInfoImageUploadComponent';

export default function MeasurementInfo() {

    const jobId = useSelector((store: IStore) => store.job.jobId);
    const job = useSelector((store: IStore) => store.job.jobStatus.job);

    const center = useSelector((store: IStore) => store.user.userProfile.response?.center);

    const surveyor = useSelector((store: IStore) => store.user.userProfile.response?.surveyor);

    const userSettings = useSelector((store: IStore) => store.user.userProfile.response?.settings);
    const isUpdatingMeasurementInfo = useSelector((store: IStore) => store.job.updateJob.isFetching);

    const centerId = useSelector((store: IStore) => store.user.userProfile.response?.center?.centerId);

    const dispatch = useDispatch();

    const [measurementExpanded, setMeasurementExpanded] = React.useState(false);
    const handleMeasurementPress = () => setMeasurementExpanded(!measurementExpanded);

    const [sellerExpanded, setSellerExpanded] = React.useState(false);
    const handleSellerPress = () => setSellerExpanded(!sellerExpanded);

    const [surveyorExpanded, setSurveyorExpanded] = React.useState(false);
    const handleSurveyorPress = () => setSurveyorExpanded(!surveyorExpanded);

    const [otherReadingsExpanded, setOtherReadingsExpanded] = React.useState(false);
    const handleOtherReadingsPress = () => setOtherReadingsExpanded(!otherReadingsExpanded);

    const [measurementFormData, setMeasurementFormData] = React.useState({});

    const [sellerFormData, setSellerFormData] = React.useState({});

    const [surveyorFormData, setSurveyorFormData] = React.useState({});

    const [otherReadingsFormData, setOtherReadingsFormData] = React.useState({});

    const [measurementFormFields, setMeasurementFormFields] = React.useState<InfoField[]>([]);
    const [sellerFormFields, setSellerFormFields] = React.useState<InfoField[]>([]);
    const [surveyorFormFields, setSurveyorFormFields] = React.useState<InfoField[]>([]);
    const [otherReadingsFormFields, setOtherReadingsFormFields] = React.useState<InfoField[]>([]);

    useEffect(() => {
        if (userSettings !== null && userSettings !== undefined) {
            const measurementInfoFields = userSettings.info_fields.filter((item) => item.category === "measurement");
            setMeasurementFormFields(measurementInfoFields);

            const sellerInfoFields = userSettings.info_fields.filter((item) => item.category === "seller");
            setSellerFormFields(sellerInfoFields);

            const surveyorInfoFields = userSettings.info_fields.filter((item) => item.category === "surveyor");
            setSurveyorFormFields(surveyorInfoFields);

            const otherReadingsFields = userSettings.info_fields.filter((item) => item.category === "other_readings");
            setOtherReadingsFormFields(otherReadingsFields);

            setMeasurementExpanded(false);
            setSellerExpanded(false);
            setSurveyorExpanded(false);
            setOtherReadingsExpanded(false);
        }
    }, [userSettings]);

    const saveMeasurementInfo = () => {
        if (jobId !== null && jobId !== undefined && centerId !== undefined) {

            const infoMap: { [key: string]: string } = {
                ...measurementFormData,
                ...sellerFormData,
                ...surveyorFormData,
                ...otherReadingsFormData,
                "centerId": centerId,
                "centerName": center?.name || "",
                "centerDistrict": center?.addressDistrict || "",
                "centerCity": center?.addressCity || ""
            };

            dispatch(JobAction.updateJob.request({
                jobId: jobId,
                info: infoMap
            }));
        }
    }

    return (<ScrollView>
        <Box
            key="measurementInfoContainer"
            style={containerStyle.mainContainer}>
            <Box
                key={"measurementInfo"}
                style={{
                    margin: 4
                }}>
                {
                    measurementFormFields.length > 0 &&
                    <>
                        <Pressable
                            key={"measurementInfoHeaderPressable"}
                            onPress={() => {
                                handleMeasurementPress()
                            }}
                            rounded="8"
                            overflow="hidden">
                            <HStack
                                key={"measurementInfoHeader"}
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                <Text
                                    key={"measurementInfoHeaderText"}
                                    style={textStyle.measurementSectionText}>Measurement information</Text>
                                <IconButton
                                    variant="unstyled"
                                    _focus={{
                                        borderWidth: 0
                                    }}
                                    onPress={() => {
                                        handleMeasurementPress()
                                    }}
                                    icon={<MaterialIconByName name={measurementExpanded ? "chevron-up" : "chevron-down"} />} />
                            </HStack>
                        </Pressable>
                        <Collapsible collapsed={!measurementExpanded}>
                            <VStack>
                                <FormControl
                                    key={"measurementInfoFormControl"}
                                >
                                    <FormControl.Label _text={textStyle.inputLabel}>Sample ID</FormControl.Label>
                                    <Input
                                        key={"jobId"}
                                        style={inputStyle.measurementInput}
                                        placeholder="Sample ID"
                                        isDisabled={true}
                                        value={jobId} />

                                    {
                                        center &&
                                        center.centerId &&
                                        <>
                                            <FormControl.Label _text={textStyle.inputLabel}>Center / Warehouse ID</FormControl.Label>
                                            <Input
                                                key={"centerId"}
                                                style={inputStyle.measurementInput}
                                                placeholder="Center / Warehouse ID"
                                                isDisabled={true}
                                                value={center.centerId} />
                                        </>
                                    }
                                    <FormControl.Label _text={textStyle.inputLabel}>Center / Warehouse name</FormControl.Label>
                                    <Input
                                        key={"centerName"}
                                        style={inputStyle.measurementInput}
                                        placeholder="Center / Warehouse name"
                                        isDisabled={true}
                                        value={center?.name} />
                                    {
                                        center &&
                                        center.addressDistrict &&
                                        center.addressDistrict !== "" &&
                                        <>
                                            <FormControl.Label _text={textStyle.inputLabel}>Center / Warehouse District</FormControl.Label>
                                            <Input
                                                key={"warehouseDistrict"}
                                                style={inputStyle.measurementInput}
                                                placeholder="District"
                                                isDisabled={true}
                                                value={center.addressDistrict} />
                                        </>
                                    }
                                    {
                                        center &&
                                        center.addressCity &&
                                        center.addressCity !== "" &&
                                        <>
                                            <FormControl.Label _text={textStyle.inputLabel}>Warehouse City</FormControl.Label>
                                            <Input
                                                key={"warehouseCity"}
                                                style={inputStyle.measurementInput}
                                                placeholder="City"
                                                isDisabled={true}
                                                value={center.addressCity} />
                                        </>
                                    }
                                    {
                                        measurementFormFields.map((item: InfoField, index) => {
                                            return (
                                                <>
                                                    {
                                                        item.type === "imageUpload" ?
                                                            <JobInfoImageUploadComponent
                                                                jobId={jobId}
                                                                displayName={item.displayName}
                                                                fieldName={item.fieldName}
                                                                onSuccess={(s3Path) => {
                                                                    setMeasurementFormData({
                                                                        ...measurementFormData,
                                                                        [item.fieldName]: s3Path
                                                                    });
                                                                }} />
                                                            : <>
                                                                <FormControl.Label _text={textStyle.inputLabel}>{item.displayName}</FormControl.Label>
                                                                <Input
                                                                    key={item.fieldName}
                                                                    value={job?.info?.[item.fieldName]}
                                                                    style={inputStyle.measurementInput}
                                                                    placeholder={item.displayName}
                                                                    inputMode={item.type === "number" ? "decimal" : "text"}
                                                                    onChangeText={value => setMeasurementFormData({
                                                                        ...measurementFormData,
                                                                        [item.fieldName]: value
                                                                    })} />
                                                            </>
                                                    }
                                                </>
                                            )
                                        })
                                    }

                                </FormControl>
                            </VStack>
                        </Collapsible>
                    </>
                }

                {
                    sellerFormFields.length > 0 &&
                    <>
                        <Pressable
                            onPress={() => {
                                handleSellerPress()
                            }}
                            key={"sellerInfoHeaderPressable"}
                            rounded="8"
                            overflow="hidden">
                            <HStack
                                key={"sellerInfoHeader"}
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                <Text style={textStyle.measurementSectionText}>Seller information</Text>
                                <IconButton
                                    variant="unstyled"
                                    _focus={{
                                        borderWidth: 0
                                    }}
                                    onPress={() => {
                                        handleSellerPress()
                                    }}
                                    icon={<MaterialIconByName name={measurementExpanded ? "chevron-up" : "chevron-down"} />} />
                            </HStack>

                        </Pressable>

                        <Collapsible
                            key={"sellerInfoCollapsible"}
                            collapsed={!sellerExpanded}>
                            <VStack>
                                <FormControl
                                    key={"sellerInfoFormControl"}
                                >
                                    {
                                        sellerFormFields.map((item: InfoField, index) => {
                                            return (
                                                <>
                                                    {
                                                        item.type === "imageUpload" ?
                                                            <JobInfoImageUploadComponent
                                                                jobId={jobId}
                                                                displayName={item.displayName}
                                                                fieldName={item.fieldName}
                                                                onSuccess={(s3Path) => {
                                                                    setMeasurementFormData({
                                                                        ...sellerFormData,
                                                                        [item.fieldName]: s3Path
                                                                    });
                                                                }} />
                                                            : <>
                                                                <FormControl.Label _text={textStyle.inputLabel}>{item.displayName}</FormControl.Label>
                                                                <Input
                                                                    key={item.fieldName}
                                                                    value={job?.info?.[item.fieldName]}
                                                                    style={inputStyle.measurementInput}
                                                                    placeholder={item.displayName}
                                                                    onChangeText={value => setSellerFormData({
                                                                        ...sellerFormData,
                                                                        [item.fieldName]: value
                                                                    })} />
                                                            </>
                                                    }
                                                </>
                                            )
                                        })
                                    }

                                </FormControl>
                            </VStack>

                        </Collapsible>
                    </>
                }

                {
                    surveyorFormFields.length > 0 &&
                    <>
                        <Pressable
                            key={"surveyorInfoHeaderPressable"}
                            onPress={() => {
                                handleSurveyorPress()
                            }}
                            rounded="8"
                            overflow="hidden">
                            <HStack
                                key={"surveyorInfoHeader"}
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                <Text style={textStyle.measurementSectionText}>Surveyor information</Text>
                                <IconButton
                                    variant="unstyled"
                                    _focus={{
                                        borderWidth: 0
                                    }}
                                    onPress={() => {
                                        handleSurveyorPress()
                                    }}
                                    icon={<MaterialIconByName name={measurementExpanded ? "chevron-up" : "chevron-down"} />} />
                            </HStack>

                        </Pressable>

                        <Collapsible
                            key={"surveyorInfoCollapsible"}
                            collapsed={!surveyorExpanded}>
                            <VStack>
                                <FormControl
                                    key={"surveyorInfoFormControl"}
                                >
                                    {
                                        surveyorFormFields.map((item: InfoField, index) => {
                                            return (
                                                <>
                                                    <FormControl.Label _text={textStyle.inputLabel}>{item.displayName}</FormControl.Label>
                                                    <Input
                                                        key={item.fieldName}
                                                        value={(surveyor && surveyor[getMappedFieldName(item.fieldName)] !== null
                                                            && surveyor[getMappedFieldName(item.fieldName)] !== undefined) ?
                                                            surveyor[getMappedFieldName(item.fieldName)]
                                                            : job?.info?.[item.fieldName]}
                                                        style={inputStyle.measurementInput}
                                                        placeholder={item.displayName}
                                                        isDisabled={surveyor !== null
                                                            && surveyor !== undefined
                                                            && surveyor[getMappedFieldName(item.fieldName)] !== null
                                                            && surveyor[getMappedFieldName(item.fieldName)] !== undefined}
                                                        onChangeText={value => setSurveyorFormData({
                                                            ...surveyorFormData,
                                                            [item.fieldName]: value
                                                        })} />
                                                </>
                                            )
                                        })
                                    }

                                </FormControl>
                            </VStack>

                        </Collapsible>
                    </>
                }

                {
                    otherReadingsFormFields.length > 0 &&
                    <>
                        <Pressable
                            key={"otherReadingsHeaderPressable"}
                            onPress={() => {
                                handleOtherReadingsPress()
                            }}
                            rounded="8"
                            overflow="hidden">
                            <HStack
                                key={"otherReadingsHeader"}
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                <Text style={textStyle.measurementSectionText}>Other readings</Text>
                                <IconButton
                                    variant="unstyled"
                                    _focus={{
                                        borderWidth: 0
                                    }}
                                    onPress={() => {
                                        handleOtherReadingsPress()
                                    }}
                                    icon={<MaterialIconByName name={measurementExpanded ? "chevron-up" : "chevron-down"} />} />
                            </HStack>

                        </Pressable>

                        <Collapsible
                            key={"otherReadingsCollapsible"}
                            collapsed={!otherReadingsExpanded}>
                            <VStack>
                                <FormControl
                                    key={"otherReadingsFormControl"}
                                >
                                    {
                                        otherReadingsFormFields.map((item: InfoField, index) => {
                                            return (
                                                <>
                                                    {
                                                        item.type === "imageUpload" ?
                                                            <JobInfoImageUploadComponent
                                                                jobId={jobId}
                                                                displayName={item.displayName}
                                                                fieldName={item.fieldName}
                                                                onSuccess={(s3Path) => {
                                                                    setOtherReadingsFormData({
                                                                        ...otherReadingsFormData,
                                                                        [item.fieldName]: s3Path
                                                                    });
                                                                }} />
                                                            : <>
                                                                <FormControl.Label _text={textStyle.inputLabel}>{item.displayName}</FormControl.Label>
                                                                <Input
                                                                    key={item.fieldName}
                                                                    value={job?.info?.[item.fieldName]}
                                                                    style={inputStyle.measurementInput}
                                                                    placeholder={item.displayName}
                                                                    onChangeText={value => setOtherReadingsFormData({
                                                                        ...otherReadingsFormData,
                                                                        [item.fieldName]: value
                                                                    })} />
                                                            </>
                                                    }

                                                </>
                                            )
                                        })
                                    }

                                </FormControl>
                            </VStack>

                        </Collapsible>
                    </>
                }

            </Box>


            <Button isLoading={isUpdatingMeasurementInfo}
                style={buttonStyle.buttonWithTopMargin}
                isLoadingText={"Saving"}
                onPress={saveMeasurementInfo}>
                SAVE MEASUREMENT INFO
            </Button>
        </Box>
    </ScrollView>
    );
}