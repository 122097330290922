import React from 'react';

import { Icon } from 'native-base';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

interface MaterialIconByNameProps {
    name: string;
    size?: string;
    color?: string;
    style?: any;
}

export default function MaterialIconByName(props: MaterialIconByNameProps) {
    return <Icon as={MaterialCommunityIcons}
        style={props.style}
        name={props.name}
        size={props.size}
        color={props.color} />
};