import React from 'react';

import { Text, HStack } from 'native-base';
import { listStyle } from 'src/styles/listStyle';
import MaterialIconByName from '../MaterialIconByName';

interface TextWithIconProps {
    text: String | undefined;
    icon: string;
}

export default function TextWithIcon(props: TextWithIconProps) {

    return <HStack
        style={{
            flexDirection: "row",
            alignItems: "center",
        }}
        space={1}>
        <MaterialIconByName name={props.icon} />
        <Text
            style={listStyle.textWithIcon}>
            {props.text || ""}
        </Text>
    </HStack>
};