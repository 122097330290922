import React from 'react';
import { Box, ScrollView, Text } from 'native-base';

import CentersWithTrucksListComponent from '../list/CentersWithTrucksListComponent';
import CenterDistrictsListComponent from '../list/CenterDistrictsListComponent';
import CentersWithStatsListComponent from '../list/CentersWithStatsListComponent';
import { useSelector } from 'react-redux';
import { IStore } from 'src/redux/reducers';

export default function OrgSummaryComponent({ navigation }) {
    const centerIdFilter = useSelector((store: IStore) => store.app.centerIdFilter) || "all";
    const stateFilter = useSelector((store: IStore) => store.app.stateFilter) || "all";
    const districtFilter = useSelector((store: IStore) => store.app.districtFilter) || "all";

    return (
        <ScrollView>
            <Box>
                {districtFilter === "all" && centerIdFilter === "all" ? (
                    <CenterDistrictsListComponent navigation={navigation} />
                ) : centerIdFilter === "all" ? (
                    <CentersWithStatsListComponent navigation={navigation} />
                ) : (
                    <CentersWithTrucksListComponent navigation={navigation} />
                )}
            </Box>
        </ScrollView>
    );
};
